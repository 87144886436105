/* eslint-env browser */
/* global PARTYKIT_HOST */

import "./styles.css";
import interact from 'interactjs'


import PartySocket from "partysocket";

/** @type {HTMLDivElement} - The DOM element to append all messages we get */
const output = document.getElementById("app");


/**
 * A PartySocket is like a WebSocket, but with more features.
 * It handles reconnection logic, buffering messages while it's offline, etc.
 * @type {PartySocket} - The connection object
 */
const conn = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

//const words = ["hello", "world", "partykit", "is", "cool"];
// object to keep track of word positions

//const wordCards = ["hello", "world", "partykit", "is", "cool"]
//const wordsString = '& a a a about above act again age air all alone always am an an and and and and angry apartment are are as as ask at at away bacon bad be be beauty bed been begin being believe belong bitter blank blue break bring burn but but by by call can car change chocolate city clock coffee cold come could cry curse d day dead deep did die different dirty do dog door dream drink drive dry e easy eat ed ed empty end er es es est ever eye face family far feel fight find fire fix for for forever forget forgive friend from from ful funny garden gas get girl give go gone good hair hand happen happiness happy hard has has have have he he heart heavy help her her here high him him his his hold hope hour how hungry I I I I ice if in in in ing ing ing is is it it keep kill kiss kitchen know late laugh lazy learn less let letter life like like listen little live lock lone long look love ly ly mad make man matter me me mean meet mind minute miss mixtape money moon morning move movie music must my my name near need never new next night no not not nothing now number of of okay old on on one or or our out over own page paper party people phone play please pretty purple put question quick r r rain re read ready reason red right road room rough run s s s s sad safe said same save say see seek shadow she she short should show sick silence sit skin sky sleep slow small smell so some song sorry start stay still stop story sugar summer sun sure sweet table take talk tear tell thank the the the their there these they they thing think those thought through time to to today try TV up us use vacation very wait walk want warm was was water way we week well were wet what when white who why will will window wish with with wonder word work would write y y yet you you you young your your'

//const wordsString = 'LinkedIn is a business and employment social media platform that works through websites and mobile apps It was launched on May 5 2003 Since December 2016 it has been a wholly owned subsidiary of Microsoft The platform is primarily used for professional networking and career development and allows jobseekers to post their CVs and employers to post jobs From 2015 most of the company revenue came from selling access to information about its members to recruiters and sales professionals LinkedIn has more than 930 million registered members from over 200 countries and territories LinkedIn allows members both workers and employers to create profiles and connect with each other in an online social network which may represent real-world professional relationships Members can invite anyone whether an existing member or not to become a connection LinkedIn can also be used to organize offline events join groups write articles publish job postings post photos and videos and more'
const wordsString = 'Excited to announce my new role as at Grateful for this opportunity and looking forward to new challenges Celebrating years at today grateful for the incredible journey and the fantastic team I work with Just attended a great webinar on here are the key takeaways I am thrilled to have achieved milestone in my career It would not have been possible without the support of my amazing colleagues and mentors Here are my thoughts on the future of What do you think the next big trends will be a quote that keeps me motivated what inspires you in your professional journey this article on is a must-read what are your thoughts on how it impacting our field honored to have been recognized with the by Thank you to my team for your support Grateful for the opportunities and friendships I have found through LinkedIn thank you for your support am excited to share the latest project have been working on Check it out My journey from to Professional Success taught me the importance of resilience and adaptability I am looking to connect with experts in Field Any recommendations or introductions would be greatly appreciated I am proud to share that our team successfully Achieved a Goal Project Teamwork and dedication paid off We are hiring for Job Title at Company If you or someone you know is interested please reach out'


const wordCards = [];




interact('.draggable').draggable({
  listeners: {
    start (event) {
      console.log(event.type, event.target)
    },
    move: dragMoveListener
  }
})


function dragMoveListener (event) {
  var target = event.target
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

  // update the posiion attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y);
  // get index of word
  const index = wordCards.indexOf(target.innerText);

  const data = [index, x, y];

  sendPosistion(data);
}

// cursor
/* document.addEventListener("mousemove", (event) => {
  const mouseData = {
    x: event.clientX / window.innerWidth, // X coordinate of the mouse cursor
    y: event.clientY / window.innerHeight, // Y coordinate of the mouse cursor
  };


  // Convert mouseData to a JSON string and send it to the server.
  const mouseDataString = JSON.stringify(mouseData);
  // Replace 'conn' with your actual connection object.
  conn.send(mouseDataString);
}); */

function sendPosistion(data) {
  conn.send(JSON.stringify(data)); // Send a message to the server
}

const moveCard = (data) => {
  console.log('moveCard')
  const index = data[0];
  const x = data[1];
  const y = data[2];
  const $wordCard = document.querySelector(`.word:nth-child(${index + 2})`);
  $wordCard.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  $wordCard.setAttribute('data-x', x);
  $wordCard.setAttribute('data-y', y);
}

const cursorElements = {};
// Create a function to display the cursor for other clients.
function displayCursor(cursorData) {
  // Extract client's cursor position and ID from the data.
  const { x, y, id } = cursorData;

  // Create or update the cursor element for the client.
  if (!cursorElements[id]) {
    const cursorElement = document.createElement("div");
    cursorElement.classList.add("cursor");
    cursorElement.id = `cursor-${id}`;
    document.body.appendChild(cursorElement);
    cursorElements[id] = cursorElement;
  }

  // Update the cursor's position.
  const cursorElement = cursorElements[id];
  cursorElement.style.left = x * window.innerWidth + "px";
  cursorElement.style.top = y * window.innerHeight + "px";
}

const setStartPositions = (data) => {
  console.log('setStartPositions')
  for(let i = 1; i < data.length; i++){
    moveCard(data[i]);
  }
  }


conn.addEventListener("message", message => {
  try {
    const data = JSON.parse(message.data);
    //displayCursor(mouseData);

    console.log(data);
    if(data[0] == 'positions'){
      setStartPositions(data);
    }
    
    if(data.length == 3){
      moveCard(data);
    }
  } catch {
    if(message.data == "connected"){
      console.log("connected");
    }
  }
});

const createWordCards = () => {
  const words = wordsString.split(' ');
  words.forEach(word => {
    // switch to lowercase
    word = word.toLowerCase();
    wordCards.push(word);
  });
}

createWordCards();
console.log(wordCards.length);
// create word cards
wordCards.forEach(wordCard => {
  const $container = document.querySelector(".container");
  const $wordCard = document.createElement("div");
  $wordCard.classList.add("word");
  $wordCard.classList.add("draggable");
  $wordCard.innerText = wordCard;
  


  //document.body.appendChild($wordCard);
  $container.appendChild($wordCard);
});




